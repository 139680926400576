import React from "react";
import { StatusBadge } from "../../utils/components/Common";
import { getLastUpdated } from "../../utils/datetime";
import { useGetMonitorsQuery } from "../monitors/monitorApi";
import { User } from "../user/userApi";
import { Status } from "./dashboardApi";

type DashboardCardProps = {
  user: User;
  isLoggedIn: boolean;
  status: Status;
};

export const DashboardCard = ({
  user,
  isLoggedIn,
  status,
}: DashboardCardProps): JSX.Element => {
  const { data: monitors } = useGetMonitorsQuery();

  return (
    <>
      {isLoggedIn && user ? (
        <div className="p-4 max-w-sm">
          <div className="flex rounded-lg h-full dark:bg-gray-800 bg-teal-400 p-8 flex-col">
            <div className="flex items-center mb-3">
              <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full dark:bg-indigo-500 bg-indigo-500 text-white flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                </svg>
              </div>
              {monitors?.data.map((monitor) =>
                monitor.id === status.monitor ? (
                  <h2
                    className="text-white dark:text-white textLg font-medium"
                    key={monitor.id}
                  >
                    {monitor.url}
                  </h2>
                ) : null
              )}
            </div>
            <div className="flex flex-col justify-between flex-grow">
              <p className="leading-relaxed text-base text-white dark:text-gray-300">
                <b>Status:</b> <StatusBadge status={status.status} />
              </p>
              <p className="leading-relaxed text-base text-white dark:text-gray-300">
                <b>Avg Response Time:</b> {status.response_time_avg?.toFixed(2)}
                s
              </p>
              <p className="leading-relaxed text-base text-white dark:text-gray-300">
                <b>Last Updated:</b>{" "}
                {getLastUpdated(status.last_updated as string)}
              </p>

              <a
                href="#"
                className="mt-3 text-black dark:text-white hover:text-blue-600 inline-flex items-center"
              >
                Manage
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
