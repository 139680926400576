import React, { useEffect, useState } from "react";

/* Config */
import config from "../../app/config.json";

/* Components */
import {
  Container,
  ControlledSelect,
  FormButton,
  LoadingScreen,
  RefreshRateSlider,
} from "../../utils/components/Common";

/* APIs - Hooks - Utils */
import { useAppSelector } from "../../app/hooks";
import { DashboardCard } from "./Dashboard.elements";
import { useListStatusesQuery } from "./dashboardApi";
import { toastQueryError } from "../../utils/toasts";
import { IoReloadSharp } from "react-icons/io5";

const Dashboard = (): JSX.Element => {
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const user = useAppSelector((state) => state.user.user);
  const [processing, setProcessing] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageItems] = useState(10);
  const [pollingInterval, setPollingInterval] = useState(
    config.constants.refreshRate.maxPollingInterval / 2
  );
  const {
    data: statuses,
    error,
    isFetching,
    isError,
    isLoading,
    refetch,
  } = useListStatusesQuery(
    { page: page, page_size: pageSize },
    { pollingInterval: pollingInterval }
  );

  const onPageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPageItems(parseInt(e.target.value));
    setPage(1);
  };

  const onRefetchRateSliderChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPollingInterval(parseInt(e.target.value));
  };

  const showLastPage = () => {
    if (statuses?.page_total) {
      if (statuses.page_total > 1) {
        return (
          <FormButton
            txtColor="text-white"
            bgColor="bg-blue-800"
            type="button"
            onClick={() => setPage(statuses.page_total)}
            disabled={statuses?.page === statuses?.page_total}
          >
            {">>"}
          </FormButton>
        );
      }
    }
  };

  const showFirstPage = () => {
    if (statuses?.page_total) {
      if (statuses.page_total != 1) {
        return (
          <FormButton
            txtColor="text-white"
            bgColor="bg-blue-800"
            type="button"
            onClick={() => setPage(1)}
            disabled={statuses?.page === 1}
          >
            {"<<"}
          </FormButton>
        );
      }
    }
  };

  useEffect(() => {
    if (error) {
      toastQueryError(error);
    }
  }, [isError]);

  useEffect(() => {
    if (isFetching || isLoading) {
      setProcessing(true);
    } else {
      setProcessing(false);
    }
    if (isError && error) {
      toastQueryError(error);
    }
  }, [isLoading, isFetching]);

  return (
    <>
      {processing ? <LoadingScreen /> : null}

      {user && isLoggedIn ? (
        <>
          {/* TODO: Create into Component (Pagination) */}
          <Container padding="px-16 py-6">
            <div className="flex space-x-4">
              <FormButton
                txtColor="text-white"
                bgColor="bg-teal-500"
                type="button"
                onClick={() => refetch()}
              >
                <IoReloadSharp className="inline" />
              </FormButton>
              {showFirstPage()}
              <FormButton
                txtColor="text-white"
                bgColor="bg-blue-500"
                type="button"
                onClick={() => setPage((prevPage) => prevPage - 1)}
                disabled={statuses?.page === 1}
              >
                {"<"}
              </FormButton>
              <p>
                Page <b>{statuses?.page}</b> of <b>{statuses?.page_total}</b>
              </p>
              <FormButton
                txtColor="text-white"
                bgColor="bg-blue-500"
                type="button"
                onClick={() => setPage((prevPage) => prevPage + 1)}
                disabled={statuses?.page === statuses?.page_total}
              >
                {">"}
              </FormButton>
              {showLastPage()}
              <ControlledSelect
                onChange={onPageSizeChange}
                selectType="pageItems"
              />
            </div>
          </Container>
          <RefreshRateSlider
            pollingInterval={pollingInterval}
            onRefreshSliderChange={onRefetchRateSliderChange}
          />
          <Container
            flex="flex"
            flexWrap="flex-wrap"
            justifyContent="justify-center"
            margin="mt-10"
          >
            {statuses?.data.map((status) => (
              <DashboardCard
                key={status.id}
                user={user}
                isLoggedIn={isLoggedIn}
                status={status}
              />
            ))}
          </Container>
        </>
      ) : null}
    </>
  );
};

export default Dashboard;
