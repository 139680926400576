import React from "react";

/* Config */

/* Components */

/* APIs - Utils*/
import { useAppSelector } from "../../app/hooks";
import LoginForm from "../auth/LoginForm";
import Dashboard from "../dashboard/Dashboard";

const Landing = (): JSX.Element => {
  const user = useAppSelector((state) => state.user.user);

  return <>{!user ? <LoginForm /> : <Dashboard />}</>;
};

export default Landing;
