import { apiSlice } from "../api/apiSlice";
import { ListResponse, PageRequest } from "../api/pagination";

export interface Status {
  id: number;
  status: string;
  monitor: number;
  response_time_avg: number | null;
  last_updated: string;
}

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    listStatuses: builder.query<ListResponse<Status>, PageRequest>({
      query({ page, page_size }) {
        return {
          url: `uptime/status?page=${page}&page_size=${page_size}`,
          method: "GET",
        };
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      providesTags(result, error, page) {
        return result
          ? [
              ...result.data.map(({ id }) => ({
                type: "Statuses" as const,
                id: id,
              })),
              { type: "Statuses", id: "PARTIAL-LIST" },
            ]
          : [{ type: "Statuses", id: "PARTIAL-LIST" }];
      },
    }),
  }),
});

export const { useListStatusesQuery } = extendedApiSlice;

export const selectStatusesResult =
  extendedApiSlice.endpoints.listStatuses.select({ page: 1, page_size: 50 });
