import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

export function getDatetimeString(date = new Date()): string {
  return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
}

export function formatDateTimeString(
  date_time_string: string,
  format: string
): string {
  return dayjs(date_time_string).format(format);
}

export function getLastUpdated(date_time_string: string): string {
  const last_updated = dayjs(date_time_string);
  const now = dayjs();
  const diff = now.diff(last_updated, "minute");
  if (diff < 1) {
    return "a few seconds ago";
  } else if (diff < 60) {
    return `${diff} minute${diff > 1 ? "s" : ""} ago`;
  } else {
    return last_updated.fromNow();
  }
}
