import { apiSlice } from "../api/apiSlice";
import { ListResponse, PageRequest } from "../api/pagination";

export interface Report {
  id: number;
  status_code: number;
  reason: string;
  is_ok: boolean;
  is_redirect: boolean;
  elapsed_total_seconds: number;
  request_time: string;
  monitor: number;
}

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    listReports: builder.query<ListResponse<Report>, PageRequest>({
      query({ page, page_size }) {
        return {
          url: `uptime/reports?page=${page}&page_size=${page_size}`,
          method: "GET",
        };
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      providesTags(result, error, page) {
        return result
          ? [
              ...result.data.map(({ id }) => ({
                type: "Reports" as const,
                id: id,
              })),
              { type: "Reports", id: "PARTIAL-LIST" },
            ]
          : [{ type: "Reports", id: "PARTIAL-LIST" }];
      },
    }),
    deleteReport: builder.mutation<{ success: boolean; id: number }, number>({
      query(id) {
        return {
          url: `reports/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, id) => [
        { type: "Reports", id },
        { type: "Reports", id: "PARTIAL-LIST" },
      ],
    }),
  }),
});

export const { useListReportsQuery } = extendedApiSlice;

export const selectReportsResult =
  extendedApiSlice.endpoints.listReports.select({ page: 1, page_size: 50 });
